import React from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import {
  LandingScreen,
  AccountSearchScreen,
  AccountRegisterSreen,
  UnVerifiedEmailScreen,
  VerifiedEmailScreen,
  //__
  SettingsScreen,
  SettingLanguagesScreen,
  SettingsAccountInfoScreen,
  //__
  RequestsLandingScreen,
  MaintainanceRequestCreateScreen,
  MaintainanceRequestDetailsScreen,
  MaintainanceRequestListsScreen,
  // __
  RentLandingScreen,
  RentLinkedAccountsListScreen,
  RentLinkedAccountsCreateBankScreen,
  RentLinkedAccountsCreateCardScreen,
  RentActivityListScreen,
  RentPaymentScreen,
  RentReportingInfoScreen,
  RentReportingConfirmationScreen,
  RentReportingHomeScreen,
  RentReportingListScreen,
  // __
  Error401Screen,
  Error404Screen,
  Error404ApiScreen,
  // __
  HomeScreen,
  // __
  PropertyLandingScreen,
} from "../pages";
import PrivateRoute from "./PrivateRoute";
import { RegisterProspectScreen } from "../pages/Account/Register/RegisterProspect";
import { LeaseApplication } from "../pages/Account/Register/LeaseApplication";
import { LeasePayment } from "../pages/Account/Register/LeasePayment";
import { SettingsQuestionScreen } from "../pages/Settings/Question";
import Docs from "../pages/Settings/Docs";
import { useAuth0 } from "@auth0/auth0-react";
import { ManualLoader } from "../components";

function FromResmanScreen() {
  const { isAuthenticated, isLoading } = useAuth0();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const url = query.get("url");
  const urlObj = new URL(url!);
  const segments = urlObj.pathname.split("/");
  const abbreviationCode = segments[segments.length - 1];
  return (
    <>
      {isLoading && <ManualLoader />}
      {isAuthenticated && !isLoading && <Navigate to="/" />}
      {!isAuthenticated && !isLoading && (
        <Navigate to={`/property/${abbreviationCode}`} />
      )}
    </>
  );
}

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/from-resman" element={<FromResmanScreen />} />
      <Route path="/" element={<LandingScreen />} />
      <Route path="/register-prospect" element={<RegisterProspectScreen />} />
      <Route path="/invitation" element={<AccountSearchScreen />} />
      <Route
        path="/invitation/:invitationId"
        element={<AccountSearchScreen />}
      />
      <Route path="/unverified-email" element={<UnVerifiedEmailScreen />} />
      <Route path="/verified-email" element={<VerifiedEmailScreen />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/register" element={<AccountRegisterSreen />} />
      </Route>
      {/**-----*/}
      <Route path="*" element={<Error404Screen />} />
      <Route path="/error/401" element={<Error401Screen />} />
      <Route path="/error/api/404" element={<Error404ApiScreen />} />

      {/**-----*/}
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/settings" element={<SettingsScreen />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/settings/languages"
          element={<SettingLanguagesScreen />}
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/settings/account-info"
          element={<SettingsAccountInfoScreen />}
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/settings/question" element={<SettingsQuestionScreen />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/settings/docs" element={<Docs />} />
      </Route>
      {/**-----*/}
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/requests" element={<RequestsLandingScreen />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/requests/maintenance/create"
          element={<MaintainanceRequestCreateScreen />}
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/requests/maintenance/list"
          element={<MaintainanceRequestListsScreen />}
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/requests/maintenance/detail/:id"
          element={<MaintainanceRequestDetailsScreen />}
        />
      </Route>
      {/**-----*/}
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/rent" element={<RentLandingScreen />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/rent/linked-accounts"
          element={<RentLinkedAccountsListScreen />}
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/rent/create/bank-account"
          element={<RentLinkedAccountsCreateBankScreen />}
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/rent/create/card"
          element={<RentLinkedAccountsCreateCardScreen />}
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/rent/activity/list"
          element={<RentActivityListScreen />}
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/rent/payment" element={<RentPaymentScreen />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/rent/reporting/info"
          element={<RentReportingInfoScreen />}
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/rent/reporting/confirmation"
          element={<RentReportingConfirmationScreen />}
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/rent/reporting/home"
          element={<RentReportingHomeScreen />}
        />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route
          path="/rent/reporting/list"
          element={<RentReportingListScreen />}
        />
      </Route>

      {/**-----*/}
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/home" element={<HomeScreen />} />
      </Route>
      {/**-----*/}
      <Route
        path="/property/:abbreviationCode"
        element={<PropertyLandingScreen />}
      />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/lease/application" element={<LeaseApplication />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/lease/payment" element={<LeasePayment />} />
      </Route>
    </Routes>
  );
};
